import Footer from "../component/Footer";
import Header from "../component/Header";
import ScrollToTop from "../component/ScrollToTop"
import { Link } from "react-router-dom";


export default function Home() {
    return (
        <>

            <ScrollToTop>
                <Header home={true} />

                <div className="hero-wrapper hero-2" id="hero">
                    <div
                        className="hero-2-thumb wow img-custom-anim-right"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.2s"
                    >
                        <img src="./images/hero/hero-2-1.jpg" alt="img"  className=""/>
                    </div>
                    <div className="container">
                        <div className="hero-style2">
                            <div className="row">
                                <div className="col-lg-10">
                                    <h1 className="hero-title wow img-custom-anim-right text-white d-flex">
                                        where we
                                    </h1>

                                </div>
                                <div className="col-lg-10 offset-lg-1">
                                    <h1 className="hero-title wow img-custom-anim-left text-white">
                                        transform <br className="" /> <span className="ms-5 ps-5">ideas
                                        </span>
                                    </h1>
                                </div>

                                <div className="col-lg-10">
                                    <h1 className="hero-title wow img-custom-anim-right text-white">
                                        into reality.
                                    </h1>
                                </div>
                                <div className="col-xxl-4 col-xl-5 col-lg-6">
                                    <p className="hero-text wow img-custom-anim-left text-white">
                                       We are innovators, builders, and problem-solvers.Providing Top-Tier IT Services and Innovative Solutions .
                                    </p>
                                    <div className="btn-group fade_left">
                                        <Link
                                            to="/contact"
                                            className="btn style2 wow img-custom-anim-left text-white border-light"
                                        >
                                            <span className="link-effect">
                                                <span className="effect-1">Contact Us</span>
                                                <span className="effect-1">Contact Us</span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-end">
                                <div className="col-xxl-6 col-xl-7 col-lg-9">
                                    <div className="wow img-custom-anim-right">
                                        <div className="hero-contact-wrap">
                                                 B-124 Royal Plaza, Varachha Main Rd, Near Bapa Sitaram Chowk, Nana Varachha,Surat, Gujarat 395011, India
                                        </div>
                                        <div className="hero-contact-wrap">
                                            <Link to="tel:7487853421" className="d-block">+91 74878 53421</Link>
                                            <Link to="mailto:support@hopeflymeta.com">support@hopeflymeta.com</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-fluid p-0 overflow-hidden">
                    <div className="slider__marquee clearfix marquee-wrap">
                        <div className="marquee_mode marquee__group">
                            <h6 className="item m-item">
                                <Link to="/service">
                                    <i className="fas fa-star-of-life" />
                                     Business Branding
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Web Development
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Cloud-based Application
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    AI Solution
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Metaverse Solution
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Marketing and SEO
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Web Service
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    E-commerce Plugin
                                </Link>
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="feature-area-1 space ">
                    <div className="container">
                        <div className="row justify-content-xl-between justify-content-center">
                            <div className="col-xl-4 col-lg-8 position-relative">
                                <div className="sec_title_static">
                                    <div className="sec_title_wrap">
                                        <div className="title-area">
                                            <h2 className="sec-title">What We Can Do for Our Clients</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-7 col-xl-8">
                                <div className="feature-static-wrap">
                                    <div className="feature-static">
                                        <div className="row gy-4">
                                            <div className="col-md-6">
                                                <div className="feature-card">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-1.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html">Business Branding</a>
                                                    </h4>
                                                    <p className="feature-card-text">
                                                    We craft compelling brand identities that resonate with your audience, ensuring your business stands out in a competitive market.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="feature-card">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-2.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html">Web Development
                                                        </a>
                                                    </h4>
                                                    <p className="feature-card-text">
                                                        Our expert team builds responsive and user-friendly websites, tailored to enhance your online presence and drive engagement.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="feature-static">
                                        <div className="row gy-4">
                                            <div className="col-md-6">
                                                <div className="feature-card">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-3.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html">Cloud-based Application</a>
                                                    </h4>
                                                    <p className="feature-card-text">
                                                    We develop scalable cloud applications that streamline your operations and provide secure access from anywhere, anytime.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="feature-card">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-4.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html">AI Solution</a>
                                                    </h4>
                                                    <p className="feature-card-text">
                                                       Harness the power of AI with our customized solutions, designed to optimize processes and deliver intelligent insights for your business.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="feature-static">
                                        <div className="row gy-4">
                                            <div className="col-md-6">
                                                <div className="feature-card">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-3.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html">Metaverse Solution
                                                        </a>
                                                    </h4>
                                                    <p className="feature-card-text">
                                                       Step into the future with our metaverse solutions, creating immersive experiences that engage your customers in new and innovative ways.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="feature-card">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-4.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html">Marketing and SEO</a>
                                                    </h4>
                                                    <p className="feature-card-text">
                                                        Our comprehensive marketing and SEO strategies boost your visibility and attract the right audience, driving growth and conversions.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className="feature-static">
                                        <div className="row gy-4">
                                            <div className="col-md-6">
                                                <div className="feature-card">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-3.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html">Web Service</a>
                                                    </h4>
                                                    <p className="feature-card-text">
                                                         We offer a range of web services to enhance your site’s functionality, ensuring a seamless user experience and reliable performance.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="feature-card">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-4.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html">E-commerce</a>
                                                    </h4>
                                                    <p className="feature-card-text">
                                                        Boost your online store with our custom Shopify and WooCommerce plugins, designed to enhance functionality and improve customer experience.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="client-area-1 overflow-hidden space-bottom">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8">
                                <ul className="client-list-wrap">
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-1.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-1.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-2.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-2.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-3.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-3.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-4.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-4.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-5.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-5.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-6.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-6.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-7.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-7.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-8.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-8.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-area-1 space bg-theme">
                    <div
                        className="service-img-1-1 shape-mockup wow img-custom-anim-left"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.2s"
                        data-left={0}
                        data-top="-100px"
                        data-bottom="140px"
                    >
                        <img src="./images/normal/service_2-1.jpg" alt="img" />
                    </div>
                    <div className="container">
                        <div className="row align-items-center justify-content-end">
                            <div className="col-lg-6">
                                <div className="about-content-wrap">
                                    <div className="title-area mb-0">
                                        <h2 className="sec-title ">
                                             About Hopefly
                                        </h2>
                                        <p className="sec-text mt-35 mb-40">
                                                At Hopefly, we are passionate about transforming ideas into innovative digital solutions. With a team of skilled professionals in web development, AI, and cloud  technology, we empower businesses to thrive in an ever-evolving digital landscape.
                                        </p>
                                        <p className="sec-text mt-35 mb-40">
                                                Our mission is to deliver tailored services that drive growth and enhance brand identity. Whether you’re looking for effective marketing strategies, seamless e-commerce integrations, or cutting-edge metaverse experiences, we are dedicated to helping our clients succeed.
                                        </p>
                                        <p className="sec-text mt-35 mb-40">
                                                Join us on this journey, and let’s create something extraordinary together!
                                        </p>
                                     <Link to="/about"><button className="btn style1 wow img-custom-anim-left p-3 px-4">Explore About Us</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="portfolio-area-1 space overflow-hidden">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-9">
                                <div className="title-area text-center">
                                    <h2 className="sec-title">Our Portfolio</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-between masonary-active">
                            <div className="col-lg-6 filter-item">
                                <div className="portfolio-wrap mt-lg-140">
                                    <div className="portfolio-thumb ">
                                        <a href="project-details.html">
                                            <img
                                                src="./images/portfolio/portfolio1_1.jpg"
                                                alt="portfolio"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-details">
                                        <ul className="portfolio-meta">
                                            <li>
                                                <a href="blog.html">Branding</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Development</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Marketing</a>
                                            </li>
                                        </ul>
                                        <h3 className="portfolio-title">
                                            <a href="project-details.html">
                                                Money Laundering Compliance Scanner
                                            </a>
                                        </h3>
                                        <a href="project-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">VIEW PROJECT</span>
                                                <span className="effect-1">VIEW PROJECT</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 filter-item">
                                <div className="portfolio-wrap mt-140 mt-lg-0">
                                    <div className="portfolio-thumb ">
                                        <a href="project-details.html">
                                            <img
                                                src="./images/portfolio/portfolio1_2.jpg"
                                                alt="portfolio"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-details">
                                        <ul className="portfolio-meta">
                                            <li>
                                                <a href="blog.html">Branding</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Development</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Marketing</a>
                                            </li>
                                        </ul>
                                        <h3 className="portfolio-title">
                                            <a href="project-details.html">
                                                Decentralized Lending Platform for Students
                                            </a>
                                        </h3>
                                        <a href="project-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">VIEW PROJECT</span>
                                                <span className="effect-1">VIEW PROJECT</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 filter-item">
                                <div className="portfolio-wrap mt-140">
                                    <div className="portfolio-thumb ">
                                        <a href="project-details.html">
                                            <img
                                                src="./images/portfolio/portfolio1_3.jpg"
                                                alt="portfolio"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-details">
                                        <ul className="portfolio-meta">
                                            <li>
                                                <a href="blog.html">Branding</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Development</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Marketing</a>
                                            </li>
                                        </ul>
                                        <h3 className="portfolio-title">
                                            <a href="project-details.html">
                                                Anti Money Laundering Compliance Scanner
                                            </a>
                                        </h3>
                                        <a href="project-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">VIEW PROJECT</span>
                                                <span className="effect-1">VIEW PROJECT</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 filter-item">
                                <div className="portfolio-wrap mt-140">
                                    <div className="portfolio-thumb ">
                                        <a href="project-details.html">
                                            <img
                                                src="./images/portfolio/portfolio1_4.jpg"
                                                alt="portfolio"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-details">
                                        <ul className="portfolio-meta">
                                            <li>
                                                <a href="blog.html">Branding</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Development</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Marketing</a>
                                            </li>
                                        </ul>
                                        <h3 className="portfolio-title">
                                            <a href="project-details.html">
                                                Shopify Redesign for a Nova Scotia Winery
                                            </a>
                                        </h3>
                                        <a href="project-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">VIEW PROJECT</span>
                                                <span className="effect-1">VIEW PROJECT</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 filter-item">
                                <div className="portfolio-wrap mt-140">
                                    <div className="portfolio-thumb ">
                                        <a href="project-details.html">
                                            <img
                                                src="./images/portfolio/portfolio1_5.png"
                                                alt="portfolio"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-details">
                                        <ul className="portfolio-meta">
                                            <li>
                                                <a href="blog.html">Branding</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Development</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Marketing</a>
                                            </li>
                                        </ul>
                                        <h3 className="portfolio-title">
                                            <a href="project-details.html">
                                                Product Lineup Industrial <br /> Design for Caramba
                                            </a>
                                        </h3>
                                        <a href="project-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">VIEW PROJECT</span>
                                                <span className="effect-1">VIEW PROJECT</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 filter-item">
                                <div className="portfolio-wrap mt-140">
                                    <div className="portfolio-thumb">
                                        <a href="project-details.html">
                                            <img
                                                src="./images/portfolio/portfolio1_6.png"
                                                alt="portfolio"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-details">
                                        <ul className="portfolio-meta">
                                            <li>
                                                <a href="blog.html">Branding</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Development</a>
                                            </li>
                                            <li>
                                                <a href="blog.html">Marketing</a>
                                            </li>
                                        </ul>
                                        <h3 className="portfolio-title">
                                            <a href="project-details.html">
                                                Trading Website Design &amp; Development
                                            </a>
                                        </h3>
                                        <a href="project-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">VIEW PROJECT</span>
                                                <span className="effect-1">VIEW PROJECT</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="award-area-1 space overflow-hidden">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <ul className="award-wrap-area">
                                    <li
                                        className="single-award-list style2 tg-img-reveal-item"
                                        data-fx={1}
                                        data-img="./images/award/award-1-1.png"
                                    >
                                        <span className="award-year">2017</span>
                                        <div className="award-details">
                                            <h4>
                                                <a href="">Decentralized Platform</a>
                                            </h4>
                                            <div className="award-meta">
                                                <a href="">Branding</a>
                                                <a href="">Development</a>
                                                <a href="">Marketing</a>
                                            </div>
                                        </div>
                                        <span className="award-tag">Webflow</span>
                                    </li>
                                    <li
                                        className="single-award-list style2 tg-img-reveal-item"
                                        data-fx={1}
                                        data-img="./images/award/award-1-2.png"
                                    >
                                        <span className="award-year">2019</span>
                                        <div className="award-details">
                                            <h4>
                                                <a href="">App for Business</a>
                                            </h4>
                                            <div className="award-meta">
                                                <a href="">Development</a>
                                                <a href="">Marketing</a>
                                            </div>
                                        </div>
                                        <span className="award-tag">Themeforest</span>
                                    </li>
                                    <li
                                        className="single-award-list style2 tg-img-reveal-item"
                                        data-fx={1}
                                        data-img="./images/award/award-1-3.png"
                                    >
                                        <span className="award-year">2019</span>
                                        <div className="award-details">
                                            <h4>
                                                <a href="">Website Development</a>
                                            </h4>
                                            <div className="award-meta">
                                                <a href="">Branding</a>
                                                <a href="">Development</a>
                                            </div>
                                        </div>
                                        <span className="award-tag">Framer</span>
                                    </li>
                                    <li
                                        className="single-award-list style2 tg-img-reveal-item"
                                        data-fx={1}
                                        data-img="./images/award/award-1-4.png"
                                    >
                                        <span className="award-year">2022</span>
                                        <div className="award-details">
                                            <h4>
                                                <a href="">Nova Scotia Winery</a>
                                            </h4>
                                            <div className="award-meta">
                                                <a href="">Branding</a>
                                                <a href="">Development</a>
                                                <a href="">Marketing</a>
                                            </div>
                                        </div>
                                        <span className="award-tag">Template monster</span>
                                    </li>
                                    <li
                                        className="single-award-list style2 tg-img-reveal-item"
                                        data-fx={1}
                                        data-img="./images/award/award-1-5.png"
                                    >
                                        <span className="award-year">2023</span>
                                        <div className="award-details">
                                            <h4>
                                                <a href="">Educational Platform</a>
                                            </h4>
                                            <div className="award-meta">
                                                <a href="">Marketing</a>
                                                <a href="">Branding</a>
                                            </div>
                                        </div>
                                        <span className="award-tag">Themeforest</span>
                                    </li>
                                </ul>
                                <div className="btn-wrap mt-50 justify-content-center">
                                    <a href="" className="btn">
                                        <span className="link-effect">
                                            <span className="effect-1">VIEW ALL PROJECTS</span>
                                            <span className="effect-1">VIEW ALL PROJECTS</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="video-area-1 overflow-hidden">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="video-wrap">
                                <div
                                    className="jarallax"
                                    style={{ backgroundImage: `url('./images/normal/video_2-1.jpg')` }}
                                ></div>
                                <Link
                                    to="https://www.youtube.com/watch?v=vvNwlRLjLkU"
                                    className="play-btn circle-btn btn gsap-magnetic popup-video background-image"
                                >
                                    PLAY VIDEO
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

                <section className="blog-area space">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xxl-7 col-xl-6 col-lg-8">
                                <div className="title-area text-center">
                                    <h2 className="sec-title">Explore Our Blog and News Hub</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row gy-40 justify-content-center">
                            <div className="col-lg-4 col-md-6">
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <a href="blog-details.html">
                                            <img src="./images/blog/blog_1_1.png" alt="blog image" />
                                        </a>
                                    </div>
                                    <div className="blog-content">
                                        <div className="post-meta-item blog-meta">
                                            <a href="blog.html">March 26, 2024</a>
                                            <a href="blog.html">Branding</a>
                                        </div>
                                        <h4 className="blog-title">
                                            <a href="blog-details.html">
                                                Everything You Should Know About Return
                                            </a>
                                        </h4>
                                        <a href="blog-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">READ MORE</span>
                                                <span className="effect-1">READ MORE</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <a href="blog-details.html">
                                            <img src="./images/blog/blog_1_2.png" alt="blog image" />
                                        </a>
                                    </div>
                                    <div className="blog-content">
                                        <div className="post-meta-item blog-meta">
                                            <a href="blog.html">March 26, 2024</a>
                                            <a href="blog.html">Branding</a>
                                        </div>
                                        <h4 className="blog-title">
                                            <a href="blog-details.html">
                                                6 Big Commerce Design Tips For Big Results
                                            </a>
                                        </h4>
                                        <a href="blog-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">READ MORE</span>
                                                <span className="effect-1">READ MORE</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <a href="blog-details.html">
                                            <img src="./images/blog/blog_1_3.png" alt="blog image" />
                                        </a>
                                    </div>
                                    <div className="blog-content">
                                        <div className="post-meta-item blog-meta">
                                            <a href="blog.html">March 26, 2024</a>
                                            <a href="blog.html">Branding</a>
                                        </div>
                                        <h4 className="blog-title">
                                            <a href="blog-details.html">
                                                Four Steps to Conduct a Successful Usability
                                            </a>
                                        </h4>
                                        <a href="blog-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">READ MORE</span>
                                                <span className="effect-1">READ MORE</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <div className="cta-area-1 overflow-hidden bg-smoke4  space">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                                <div className="title-area text-center mb-0">
                                    <h2 className="sec-title text-black">Let's Create Something Great</h2>
                                    <p className="sec-text mt-30 mb-40 text-black">
                                        We shift you from today’s reality to tomorrow’s potential,
                                        ensuring
                                    </p>
                                    <div className="btn-group justify-content-center">
                                        <Link to="/contact" className="btn mt-0">
                                            <span className="link-effect">
                                                <span className="effect-1">LET’S TALK WITH US</span>
                                                <span className="effect-1">LET’S TALK WITH US</span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

                <div className="scroll-top">
                    <svg
                        className="progress-circle svg-content"
                        width="100%"
                        height="100%"
                        viewBox="-1 -1 102 102"
                    >
                        <path
                            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                            style={{
                                transition: "stroke-dashoffset 10ms linear 0s",
                                strokeDasharray: "307.919, 307.919",
                                strokeDashoffset: "307.919"
                            }}
                        />
                    </svg>
                </div>
            </ScrollToTop>

        </>
    )
}