import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import ScrollToTop from "../component/ScrollToTop";


export default function Service() {
    return (
        <>

            <ScrollToTop>
                <Header />

                <div
                    className="breadcumb-wrapper"
                    style={{ backgroundImage: `url('./images/bg/breadcumb-bg1-2.jpg')` }}
                >
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">Our Services</h1>
                        </div>
                    </div>
                </div>

                {/* <div className="feature-area-1 space">
                    <div className="container">
                        <div className="row gy-4 align-items-center justify-content-center">
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/feature-icon1-1.svg" alt="icon" />
                                    </div>
                                    <h4 className="feature-card-title">
                                        <Link to="service.html">Business Branding</Link>
                                    </h4>
                                    <p className="feature-card-text">
                                        We care success relationships fuel success we love building
                                    </p>
                                    <Link to="service-details.html" className="link-btn">
                                        <span className="link-effect">
                                            <span className="effect-1">VIEW DETAILS</span>
                                            <span className="effect-1">VIEW DETAILS</span>
                                        </span>
                                        <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/feature-icon1-2.svg" alt="icon" />
                                    </div>
                                    <h4 className="feature-card-title">
                                        <Link to="service.html">Web Development</Link>
                                    </h4>
                                    <p className="feature-card-text">
                                        We care success relationships fuel success we love building
                                    </p>
                                    <Link to="service-details.html" className="link-btn">
                                        <span className="link-effect">
                                            <span className="effect-1">VIEW DETAILS</span>
                                            <span className="effect-1">VIEW DETAILS</span>
                                        </span>
                                        <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/feature-icon1-4.svg" alt="icon" />
                                    </div>
                                    <h4 className="feature-card-title">
                                        <Link to="service.html">Digital Marketing</Link>
                                    </h4>
                                    <p className="feature-card-text">
                                        We care success relationships fuel success we love building
                                    </p>
                                    <Link to="service-details.html" className="link-btn">
                                        <span className="link-effect">
                                            <span className="effect-1">VIEW DETAILS</span>
                                            <span className="effect-1">VIEW DETAILS</span>
                                        </span>
                                        <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/feature-icon1-3.svg" alt="icon" />
                                    </div>
                                    <h4 className="feature-card-title">
                                        <Link to="service.html">Metaverse Development</Link>
                                    </h4>
                                    <p className="feature-card-text">
                                        We care success relationships fuel success we love building
                                    </p>
                                    <Link to="service-details.html" className="link-btn">
                                        <span className="link-effect">
                                            <span className="effect-1">VIEW DETAILS</span>
                                            <span className="effect-1">VIEW DETAILS</span>
                                        </span>
                                        <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/feature-icon1-5.svg" alt="icon" />
                                    </div>
                                    <h4 className="feature-card-title">
                                        <Link to="service.html">Artificial intelligence
                                        </Link>
                                    </h4>
                                    <p className="feature-card-text">
                                        We care success relationships fuel success we love building
                                    </p>
                                    <Link to="service-details.html" className="link-btn">
                                        <span className="link-effect">
                                            <span className="effect-1">VIEW DETAILS</span>
                                            <span className="effect-1">VIEW DETAILS</span>
                                        </span>
                                        <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/feature-icon1-6.svg" alt="icon" />
                                    </div>
                                    <h4 className="feature-card-title">
                                        <Link to="service.html">Shopify Development</Link>
                                    </h4>
                                    <p className="feature-card-text">
                                        We care success relationships fuel success we love building
                                    </p>
                                    <Link to="service-details.html" className="link-btn">
                                        <span className="link-effect">
                                            <span className="effect-1">VIEW DETAILS</span>
                                            <span className="effect-1">VIEW DETAILS</span>
                                        </span>
                                        <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="video-area-1 overflow-hidden">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="video-wrap">
                                    <div
                                        className="jarallax"
                                        style={{ backgroundImage: `url('./images/normal/video_2-1.jpg')` }}

                                    ></div>
                                    <Link
                                        to="https://www.youtube.com/watch?v=vvNwlRLjLkU"
                                        className="play-btn popup-video background-image"
                                    ></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid p-0 overflow-hidden">
                    <div className="slider__marquee clearfix marquee-wrap">
                        <div className="marquee_mode marquee__group">
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Business Branding
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Web Development
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Metaverse Development
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Artificial intelligence
                                </Link>
                            </h6>
                        </div>
                    </div>
                </div>

                {/* <section className="space px-lg-5 px-3">
                    <div className="contaiener">
                        <h3 className=" ">Website Development</h3>
                        <p className="sec-text mb-5  text-black">Hopefly Solutions specializes in developing powerful websites that meet all your web development needs. From design to development we have covered all areas. Our team of experts ensures that the               site not only looks great but also runs smoothly across all devices. We handle everything from frontend design to backend development and make sure that online presence is on the topnotch. Our            professional web development services can take your business to the next level.
                        </p>
                        <h3>AI Applications Development
                        </h3>
                        <p className="sec-text mb-5  text-black">company which is well verseVision of Hopefly’s AI development services is to bring out innovative, unpredictable solutions that can solve complex business challenges beyond the capabilities of                 humans. We offer advanced machine learning solutions development to help enterprises achieve data-driven decision-making serving unexplored industries and technologies. You can hire the machine               learning team of our d with the required frameworks, algorithms, tools, programming languages, and other technology stacks necessary to develop industry-specific machine learning use cases.
                                </p>
                        <h4>
                        AI Development Technologies
                        </h4>
                        <h6>
                        1. Python
                        </h6>
                        <h6>
                        2. Java
                        </h6>
                        <h6>
                        3.C++
                        </h6>
                        <h3 className="mt-5">Cloud Solutions</h3>
                        <p className="sec-text mb-5  text-black">Hopefly Technologies is a cloud development company & cloud solution providers that helps organizations transform their IT environment with cost-effective Cloud services. Our team of experts delivers cutting-edge cloud computing consulting services & that help companies boost their productivity to new heights. Our cloud migration and automation services will help you upgrade your systems to drive innovation and business success. The security of your digital infrastructure and data from cyberattacks also gets enhanced with cloud transformation solutions , giving you the peace of mind required to focus on your business operations.
                        </p>
                        <h4>Cloud Technologies
                        </h4>
                        <h6>
                        1{")"} AWS
                        </h6>
                        <h6>
                        2{")"} AZURE
                        </h6>
                        <h6>
                        3{")"} GCP
                        </h6>
                        <h3 className="mt-5">SEO optimization& Digital Marketing</h3>
                        <p className="sec-text  text-black">Are you on the first page of the search engine results pages (SERPs) for keywords that match your business or brand? If not, you’re missing out on valuable leads. Search engine optimization isn’t a fad and it’s no longer an option to simply not think about it if you want to grow your business. It’s an indispensable part of any plan to market products or services and bring in more qualified leads and sales.</p>
                        <p className="sec-text mb-5  text-black">Hoopeflymeta develop tailored marketing strategies that align with your business objectives.</p>
                        <h4>QA & Testing Technologies
                        </h4>
                        <ul>
                            <li>Google Analytics</li>
                            <li>Google Search Console</li>
                            <li>Google trends</li>
                            <li>Seo</li>
                            <li>Digital Marketing all Strategy</li>
                        </ul>
                        <h3 className="mt-5">E-commerce Solutions</h3>
                        <p className="sec-text mb-5  text-black">At Hopefly, we specialize in creating tailored e-commerce solutions that help businesses thrive in the digital marketplace. Whether you’re starting fresh or looking to enhance your existing store, our team is here to support you every step of the way.
                        Our experience with both Shopify and WooCommerce allows us to recommend the best solution for your business.We focus on delivering measurable results that contribute to your business growth.
                        </p>
                        <h6>1. Shopify Development</h6>
                        <h6>2. WooCommerce Development</h6>

                        <h3 className="mt-5">Unlock New Possibilities in the Metaverse</h3>
                        <p className="sec-text text-black">Our skilled artists create stunning 3D assets and animations that bring your Metaverse experience to life.Our blend of IT, design, and marketing expertise ensures a holistic approach to your Metaverse projects.
                        </p>
                         <p className="sec-text mb-5  text-black">Gain insights into the effectiveness of your Metaverse initiatives through detailed analytics reports.Create your own marketplace for buying, selling, and trading NFTs, complete with secure transactions.
                         </p>       
                    </div>
                </section> */}
                <div className="why-area-1">
                            <section className="service_padding px-lg-5 px-3">
                                    <div className="contaiener-fluid">
                                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <img src="" alt="" />
                            </div>
                            <div className="col-lg-6 col-12">
                                    <h3 className="">Website Development</h3>
                                <p className="sec-text mb-5  text-black">Hopefly Solutions specializes in developing powerful websites that meet all your web development needs. From design to development we have covered all areas. Our team of experts ensures that the               site not only looks great but also runs smoothly across all devices. We handle everything from frontend design to backend development and make sure that online presence is on the topnotch. Our            professional web development services can take your business to the next level.
                                </p>
                                    </div>
                                    </div>
                                    </div>
                                    </section>
                            <section className="service_padding px-lg-5 px-3">
                                <div className="contaiener-fluid">
                                        <div className="row">
                                    <div className="col-lg-6 col-12 order-2 order-lg-1">
                                    <h3>AI Applications Development
                                      </h3>
                                      
                                      <p className="sec-text mb-5  text-black">company which is well verseVision of Hopefly’s AI development services is to bring out innovative, unpredictable solutions that can solve complex business challenges beyond the capabilities of humans. We offer advanced machine learning solutions development to help enterprises achieve data-driven decision-making serving unexplored industries and technologies. You can hire the machine learning team of our d with the required frameworks, algorithms, tools, programming languages, and other technology stacks necessary to develop industry-specific machine learning use cases.
                                              </p>
                                      <h4>
                                      AI Development Technologies
                                      </h4>
                                      <h6>
                                      1. Python
                                      </h6>
                                      <h6>
                                      2. Java
                                      </h6>
                                      <h6>
                                      3.C++
                                      </h6>

                                      </div>
                                      <div className="col-lg-6 col-12 order-lg-2 order-1">
                                            <img src="" alt="" />
                                      </div>
                                      </div>
                                 </div>
                            </section>
                            <section className="service_padding px-lg-5 px-3" >
                                <div className="contaiener-fluid">
                                    <div className="row">
                                    <div className="col-lg-6 col-12">
                             <img src="" alt="" />
                                  </div>
                                  <div className="col-lg-6 col-12">
                                  <h3 className="mt-5">Cloud Solutions</h3>
                                 <p className="sec-text mb-5  text-black">Hopefly Technologies is a cloud development company & cloud solution providers that helps organizations transform their IT environment with    cost-effective Cloud services. Our team of experts delivers cutting-edge cloud computing consulting services & that help companies boost their productivity to new heights. Our cloud  migration and automation services will help you upgrade your systems to drive innovation and business success. The security of your digital infrastructure and data from cyberattacks   also gets enhanced with cloud transformation solutions , giving you the peace of mind required to focus on your business operations.
                                    </p>
                                  <h4>Cloud Technologies
                                  </h4>
                                  <h6>
                                  1{")"} AWS
                                  </h6>
                                  <h6>
                                  2{")"} AZURE
                                  </h6>
                                  <h6>
                                  3{")"} GCP
                        </h6>
                            </div>
                                    </div>
                                </div>
                            </section>
                            <section className="service_padding px-lg-5 px-3" >
                                <div className="contaiener-fluid">
                                    <div className="row">
                            <div className="col-lg-6 col-12  order-2 order-lg-1">
                            <h3 className="mt-5">SEO optimization& Digital Marketing</h3>
                        <p className="sec-text  text-black">Are you on the first page of the search engine results pages (SERPs) for keywords that match your business or brand? If not, you’re missing out on valuable leads. Search engine optimization isn’t a fad and it’s no longer an option to simply not think about it if you want to grow your business. It’s an indispensable part of any plan to market products or services and bring in more qualified leads and sales.</p>
                        <p className="sec-text mb-5  text-black">Hoopeflymeta develop tailored marketing strategies that align with your business objectives.</p>
                        <h4>QA & Testing Technologies
                        </h4>
                        <ul>
                            <li>Google Analytics</li>
                            <li>Google Search Console</li>
                            <li>Google trends</li>
                            <li>Seo</li>
                            <li>Digital Marketing all Strategy</li>
                        </ul>
                            </div>
                        <div className="col-lg-6 col-12  order-1 order-lg-2" >
                            <img src="" alt="" />
                        </div>
                        </div>
                                </div>
                            </section>

                            <section className="service_padding px-lg-5 px-3" >
                                <div className="contaiener-fluid">
                                    <div className="row">
                         <div className="col-lg-6 col-12">
                                <img src="" alt="" />
                        </div>
                            <div className="col-lg-6 col-12">
                            <h3 className="mt-5">E-commerce Solutions</h3>
                        <p className="sec-text mb-5  text-black">At Hopefly, we specialize in creating tailored e-commerce solutions that help businesses thrive in the digital marketplace. Whether you’re starting fresh or looking to enhance your existing store, our team is here to support you every step of the way.
                        Our experience with both Shopify and WooCommerce allows us to recommend the best solution for your business.We focus on delivering measurable results that contribute to your business growth.
                        </p>
                        <h6>1. Shopify Development</h6>
                        <h6>2. WooCommerce Development</h6>
                            </div>
                            </div>
                                </div>
                            </section>
                            <section className="service_padding px-lg-5 px-3" >
                                <div className="contaiener-fluid">
                                    <div className="row">
                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                            <h3 className="mt-5">Unlock New Possibilities in the Metaverse</h3>
                        <p className="sec-text text-black">Our skilled artists create stunning 3D assets and animations that bring your Metaverse experience to life.Our blend of IT, design, and marketing expertise ensures a holistic approach to your Metaverse projects.
                        </p>
                         <p className="sec-text mb-5  text-black">Gain insights into the effectiveness of your Metaverse initiatives through detailed analytics reports.Create your own marketplace for buying, selling, and trading NFTs, complete with secure transactions.
                         </p>       
                            </div>
                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                            <img src="" alt="" />
                         </div>
                         </div>
                                </div>
                            </section>
                            </div>
                      
            
                <Footer />

                {/* Scroll To Top */}
                <div className="scroll-top">
                    <svg
                        className="progress-circle svg-content"
                        width="100%"
                        height="100%"
                        viewBox="-1 -1 102 102"
                    >
                        <path
                            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                            style={{
                                transition: "stroke-dashoffset 10ms linear 0s",
                                strokeDasharray: "307.919, 307.919",
                                strokeDashoffset: "307.919"
                            }}
                        />
                    </svg>
                </div>
            </ScrollToTop>
        </>

    )
}