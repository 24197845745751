import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import ScrollToTop from "../component/ScrollToTop";



export default function Blog() {
    const blog1 = {
        id: 1
    }
    const blog2 = {
        id: 2
    }
    const blog3 = {
        id: 3
    }
    return (
        <>
            <ScrollToTop>
                <Header />

                <div
                    className="breadcumb-wrapper"
                    style={{ backgroundImage: `url('./images/bg/breadcumb-bg1-8.jpg')` }}

                >
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">Blog</h1>
                        </div>
                    </div>
                </div>
                {/* blog-area */}
                <section className="blog__area space">
                    <div className="container">
                        <div className="blog__inner-wrap">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="blog-post-wrap">
                                        <div className="row gy-50 gutter-24">
                                            <div className="col-md-6">
                                                <div className="blog-post-item">
                                                    <div className="blog-post-thumb">
                                                        <Link to="/blog-detail" state={blog1}>
                                                            <img src="./images/blog/blog_post1_1.png" alt="img" />
                                                        </Link>
                                                    </div>
                                                    <div className="blog-post-content">
                                                        <div className="blog-post-meta">
                                                            <ul className="list-wrap">
                                                                <li>March 26, 2024</li>
                                                                <li>
                                                                    <Link to="" state={blog1}>Branding</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <h2 className="title">
                                                            <Link to="/blog-detail" state={blog1}>
                                                                Everything You Should Know About Return
                                                            </Link>
                                                        </h2>
                                                        <Link to="/blog-detail" state={blog1} className="link-btn">
                                                            <span className="link-effect">
                                                                <span className="effect-1">READ MORE</span>
                                                                <span className="effect-1">READ MORE</span>
                                                            </span>
                                                            <img
                                                                src="./images/icon/arrow-left-top.svg"
                                                                alt="icon"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="blog-post-item">
                                                    <div className="blog-post-thumb">
                                                        <Link to="/blog-detail" state={blog1} >
                                                            <img src="./images/blog/blog_post1_2.png" alt="img" />
                                                        </Link>
                                                    </div>
                                                    <div className="blog-post-content">
                                                        <div className="blog-post-meta">
                                                            <ul className="list-wrap">
                                                                <li>March 26, 2024</li>
                                                                <li>
                                                                    <Link to="">Branding</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <h2 className="title">
                                                            <Link to="/blog-detail" state={blog1} >
                                                                6 Big Commerce Design Tips For Big Results
                                                            </Link>
                                                        </h2>
                                                        <Link to="/blog-detail" className="link-btn" state={blog1} >
                                                            <span className="link-effect">
                                                                <span className="effect-1">READ MORE</span>
                                                                <span className="effect-1">READ MORE</span>
                                                            </span>
                                                            <img
                                                                src="./images/icon/arrow-left-top.svg"
                                                                alt="icon"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="blog-post-item">
                                                    <div className="blog-post-thumb">
                                                        <Link to="/blog-detail" state={blog1} >
                                                            <img src="./images/blog/blog_post1_3.png" alt="img" />
                                                        </Link>
                                                    </div>
                                                    <div className="blog-post-content">
                                                        <div className="blog-post-meta">
                                                            <ul className="list-wrap">
                                                                <li>March 26, 2024</li>
                                                                <li>
                                                                    <Link to="">Branding</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <h2 className="title">
                                                            <Link to="/blog-detail" state={blog1} >
                                                                Four Steps to Conduct a Successful Usability
                                                            </Link>
                                                        </h2>
                                                        <Link to="/blog-detail" className="link-btn" state={blog1} >
                                                            <span className="link-effect">
                                                                <span className="effect-1">READ MORE</span>
                                                                <span className="effect-1">READ MORE</span>
                                                            </span>
                                                            <img
                                                                src="./images/icon/arrow-left-top.svg"
                                                                alt="icon"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="blog-post-item">
                                                    <div className="blog-post-thumb">
                                                        <Link to="/blog-detail" state={blog1} >
                                                            <img src="./images/blog/blog_post1_4.png" alt="img" />
                                                        </Link>
                                                    </div>
                                                    <div className="blog-post-content">
                                                        <div className="blog-post-meta">
                                                            <ul className="list-wrap">
                                                                <li>March 26, 2024</li>
                                                                <li>
                                                                    <Link to="">Branding</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <h2 className="title">
                                                            <Link to="/blog-detail" state={blog1} >
                                                                Five Winning Voice Search Marketing Tips
                                                            </Link>
                                                        </h2>
                                                        <Link to="/blog-detail" className="link-btn" state={blog1} >
                                                            <span className="link-effect">
                                                                <span className="effect-1">READ MORE</span>
                                                                <span className="effect-1">READ MORE</span>
                                                            </span>
                                                            <img
                                                                src="./images/icon/arrow-left-top.svg"
                                                                alt="icon"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="pagination-wrap mt-50">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination list-wrap">
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        1
                                                    </Link>
                                                </li>
                                                <li className="page-item active">
                                                    <Link className="page-link" to="#">
                                                        2
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        3
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        4
                                                    </Link>
                                                </li>
                                                <li className="page-item next-page">
                                                    <Link className="page-link" to="#">
                                                        <i className="fas fa-arrow-right" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                <div className="container-fluid p-0 overflow-hidden">
                    <div className="slider__marquee clearfix marquee-wrap">
                        <div className="marquee_mode marquee__group">
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Branding Design
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Web Development
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Metaverse Development
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Artificial intelligence
                                </Link>
                            </h6>
                        </div>
                    </div>
                </div>

                <Footer />

                {/* Scroll To Top */}
                <div className="scroll-top">
                    <svg
                        className="progress-circle svg-content"
                        width="100%"
                        height="100%"
                        viewBox="-1 -1 102 102"
                    >
                        <path
                            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                            style={{
                                transition: "stroke-dashoffset 10ms linear 0s",
                                strokeDasharray: "307.919, 307.919",
                                strokeDashoffset: "307.919"
                            }}
                        />
                    </svg>
                </div>
            </ScrollToTop>
        </>

    )
}