import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import CaseStudies from "./pages/CaseStudies";
import Career from "./pages/Career";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/service"} element={<Service />} />
        <Route path={"/career"} element={<Career />} />
        <Route path={"/blog"} element={<Blog />} />
        <Route path={"/blog-detail"} element={<BlogDetail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
