import { Link } from "react-router-dom";
import Footer from "../component/Footer";
import Header from "../component/Header";
import ScrollToTop from "../component/ScrollToTop";
import ContactForm from "../component/ContactForm";



export default function About() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <div
                    className="breadcumb-wrapper"
                    style={{ backgroundImage: `url('./images/bg/breadcumb-bg1-1.jpg')` }}


                >
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                Hopefly Meta- Shaping
                                the Future of IT Services
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="counter-area-1 space overflow-hidden">
                    <div className="container">
                        <div className="title-area text-center">
                        <h2 className="sec-title">What We Do</h2>
                        </div>
                        <p className="sec-text mb-5  text-black text-center ">hopefly Web development ,Metaverse solution, sco and other services to satisfy clients needs and enhance their customer relation management needs. hopefly provides quality services and maintains a high standard for development.
                        </p>
                        <div className="row gy-40 align-items-center justify-content-lg-between justify-content-center">
                            <div className="col-xl-auto col-lg-4 col-md-6 counter-divider">
                                <div className="counter-card">
                                    <h3 className="counter-card_number">
                                        <span className="counter-number">26</span>+
                                    </h3>
                                    <h4 className="counter-card_title">Years of Experience</h4>
                                    <p className="counter-card_text">
                                        We are a creative agency brands building insightful strategy,
                                        creating unique designs helping
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-auto col-lg-4 col-md-6 counter-divider">
                                <div className="counter-card">
                                    <h3 className="counter-card_number">
                                        <span className="counter-number">347</span>+
                                    </h3>
                                    <h4 className="counter-card_title">Successful Projects</h4>
                                    <p className="counter-card_text">
                                        We are a creative agency brands building insightful strategy,
                                        creating unique designs helping
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-auto col-lg-4 col-md-6 counter-divider">
                                <div className="counter-card">
                                    <h3 className="counter-card_number">
                                        <span className="counter-number">139</span>+
                                    </h3>
                                    <h4 className="counter-card_title">Satisfied Customers</h4>
                                    <p className="counter-card_text">
                                        We are a creative agency brands building insightful strategy,
                                        creating unique designs helping
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="why-area-1 space bg-theme px-lg-5">
                    <div className="container-fluid">
                        <div className="row gx-5">
                            <div className="col-lg-4 mb-lg-0 mb-5">
                                <div className="title-area mb-45">
                                    <h2 className="sec-title">
                                        Why choose<br />  
                                        Hopefly?
                                    </h2>
                                </div>
                                <button className="btn style1 wow img-custom-anim-left p-3 px-4">Start Project</button>
                            </div>
                            <div className="col-lg-4">

                                <h4>Client satisfaction focus</h4>
                                <p>
                                    Our success is measured by your satisfaction. We prioritize understanding and exceeding client expectations, ensuring a positive experience from project initiation to delivery.
                                </p>
                                <h4 className="mt-35">
                                    Innovative solutions
                                </h4>
                                <p className="mb-n1">
                                    Beyond conventional approaches, we thrive on innovation. Our team is dedicated to exploring and implementing the latest technologies, providing clients with forward-thinking solutions that set them apart in the digital landscape.
                                </p>
                                <h4 className="mt-35">
                                    Transparent processes
                                </h4>
                                <p>
                                    Transparency is our cornerstone. From project timelines to budgets, we believe in clear communication. Our clients are kept informed at every stage, building trust and ensuring a harmonious partnership.
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h4>
                                    Agile methodology
                                </h4>
                                <p>
                                    Transparency is our cornerstone. From project timelines to budgets, we believe in clear communication. Our clients are kept informed at every stage, building trust and ensuring a harmonious partnership.
                                </p>
                                <h4 className="mt-35">
                                    Global collaboration
                                </h4>
                                <p>
                                    In a connected world, collaboration knows no bounds. We engage with clients globally, fostering diverse perspectives and enriching our solutions with a global touch. Your project benefits from a wealth of experiences and insights from around the world.
                                </p>
                                <h4 className="mt-35">
                                    Sustainability initiatives
                                </h4>
                                <p>
                                    We design solutions with longevity in mind, considering the scalability and adaptability of our work to future challenges. Our goal is to provide sustainable digital solutions that stand the test of time, offering enduring value to our clients.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="why-area-1">
                <div className="team-area-1 space-bottom overflow-hidden">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="title-area text-center">
                                    <h2 className="sec-title">Our Team Behind The Studio</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row gy-4 justify-content-center">
                            <div className="col-lg-3 col-md-6">
                                <div className="team-card">
                                    <div className="team-card_img">
                                        <img src="./images/team/team-1-1.png" alt="Team Image" />
                                    </div>
                                    <div className="team-card_content">
                                        <h3 className="team-card_title">
                                            <Link to="team-details.html">Daniyel Karlos</Link>
                                        </h3>
                                        <span className="team-card_desig">Web Developer</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="team-card">
                                    <div className="team-card_img">
                                        <img src="./images/team/team-1-2.png" alt="Team Image" />
                                    </div>
                                    <div className="team-card_content">
                                        <h3 className="team-card_title">
                                            <Link to="team-details.html">Daniyel Karlos</Link>
                                        </h3>
                                        <span className="team-card_desig">Web Developer</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="team-card">
                                    <div className="team-card_img">
                                        <img src="./images/team/team-1-3.png" alt="Team Image" />
                                    </div>
                                    <div className="team-card_content">
                                        <h3 className="team-card_title">
                                            <Link to="team-details.html">Daniyel Karlos</Link>
                                        </h3>
                                        <span className="team-card_desig">Web Developer</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="team-card">
                                    <div className="team-card_img">
                                        <img src="./images/team/team-1-4.png" alt="Team Image" />
                                    </div>
                                    <div className="team-card_content">
                                        <h3 className="team-card_title">
                                            <Link to="team-details.html">Daniyel Karlos</Link>
                                        </h3>
                                        <span className="team-card_desig">Web Developer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
               <ContactForm />
               <div className="feature-area-1 space space overflow-hidden bg_grap_color">
                    <div className="container">
                        <div className="title-area text-center">
                        <h2 className="sec-title text-white">Our Core Values</h2>
                        </div>
                               <div className="row gy-4 align-items-center justify-content-center">
                                                <div className="col-xl-4 col-md-6">
                                             <div className="feature-card bg-dark">
                                                    <div className="feature-card-icon">
                                                        <img src="./images/icon/feature-icon1-white-1.svg" alt="icon" className=""/>
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html" className="text-white">Respect</a>
                                                    </h4>
                                                    <p className="feature-card-text text-white" >
                                                    We treat every human being with respect and understand each other’s differences.
                                                    </p>
                                                    <a href="blog-details.html" className="link-btn link-undeline  text-white ">
                                            <span className="link-effect">
                                                <span className="effect-1">Apply Now</span>
                                                <span className="effect-1">Apply Now</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-white-top.svg" alt="icon" />
                                        </a>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6">
                                             <div className="feature-card bg-dark">
                                                    <div className="feature-card-icon">
                                                    <img src="./images/icon/feature-icon1-white-2.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html" className="text-white">Ethics</a>
                                                    </h4>
                                                    <p className="feature-card-text text-white" >
                                                        We operate with integrity and honesty in everything we do.
                                                    </p>
                                                    <a href="blog-details.html" className="link-btn link-undeline  text-white ">
                                            <span className="link-effect">
                                                <span className="effect-1">Apply Now</span>
                                                <span className="effect-1">Apply Now</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-white-top.svg" alt="icon" />
                                        </a>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6">
                                             <div className="feature-card bg-dark">
                                                    <div className="feature-card-icon">
                                                    <img src="./images/icon/feature-icon1-white-4.svg" alt="icon" />
                                                    </div>
                                                    <h4 className="feature-card-title">
                                                        <a href="service.html" className="text-white">Satisfaction</a>
                                                    </h4>
                                                    <p className="feature-card-text text-white" >
                                                        We work tirelessly to ensure that our clients are happy and satisfied with our work.
                                                    </p>
                                                    <a href="blog-details.html" className="link-btn link-undeline  text-white ">
                                            <span className="link-effect">
                                                <span className="effect-1">Apply Now</span>
                                                <span className="effect-1">Apply Now</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-white-top.svg" alt="icon" />
                                        </a>
                                                </div>
                                            </div>
                            </div>
                    </div>

                    </div>
                    
                    <section className="why-area-1">
                             <div className="client-area-1 overflow-hidden space-bottom">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8">
                                <ul className="client-list-wrap">
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-1.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-1.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-2.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-2.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-3.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-3.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-4.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-4.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-5.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-5.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-6.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-6.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-7.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-7.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <span className="link-effect">
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-8.svg" alt="img" />
                                                </span>
                                                <span className="effect-1">
                                                    <img src="./images/client/client-1-8.svg" alt="img" />
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                             </div>
                </section>
                <section className="why-area-1">
                    <h2 className="sec-title text-center mb-5">We worked with global largest brands</h2>

            <div class="testimonial-area-2 space bg-gray overflow-hidden">
        <div class="container-fluid p-0">
            <div class="row global-carousel testi-slider2" data-slide-show="1" data-dots="false" data-center-mode="true" data-xl-center-mode="true" data-ml-center-mode="true" data-center-padding="470px" data-xl-center-padding="380px" data-ml-center-padding="300px">
                <div class="col-lg-4">
                    <div class="testi-box style2">
                        <div class="quote-icon">
                            <img src="./images/icon/quote.svg" alt="icon"/>
                        </div>
                        <p class="testi-box_text">“It’s a pleasure working with Bunker our new brand positioning guidelines and translated them beautifully and consistently into our on-going marketing comms”</p>
                        <div class="testi-box_profile">
                            <h4 class="testi-box_name">Daniyel Karlos</h4>
                            <span class="testi-box_desig">Senior Director of Marketing</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="testi-box style2">
                        <div class="quote-icon">
                            <img src="./images/icon/quote.svg" alt="icon"/>
                        </div>
                        <p class="testi-box_text">“It’s a pleasure working with Bunker our new brand positioning guidelines and translated them beautifully and consistently into our on-going marketing comms”</p>
                        <div class="testi-box_profile">
                            <h4 class="testi-box_name">Daniyel Karlos</h4>
                            <span class="testi-box_desig">Senior Director of Marketing</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="testi-box style2">
                        <div class="quote-icon">
                            <img src="./images/icon/quote.svg" alt="icon"/>
                        </div>
                        <p class="testi-box_text">“It’s a pleasure working with Bunker our new brand positioning guidelines and translated them beautifully and consistently into our on-going marketing comms”</p>
                        <div class="testi-box_profile">
                            <h4 class="testi-box_name">Daniyel Karlos</h4>
                            <span class="testi-box_desig">Senior Director of Marketing</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    </section>
 
    <section className="why-area-1 bg-gray space">
    <div className="container">
        <div className="row">
            <div className="col-lg-6 text-white">
                <div className="d-block">         
                           <p className="text-white">Our Workflow</p>
                <h2 className="sec-title my-5 text-white">
                    This is how we approach every single project
                </h2>
                <p className="text-white">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                </p>
            </div>
            </div>

            <div className="col-lg-6">
                <div className="sc_services_content sc_item_content sc_item_posts_container sc_item_columns_1">
                    <div data-post-id="22540" className=" ">
                        <div className="d-flex justify-content-between gap_workflow">
                        <div className="sc_service_padding">
                        <h6 className="text-white d-flex justify-content-center align-items-center p-3 mb-0 round_color" >
                            01
                            </h6>
                        <div className="sc_services_item_timeline"></div>
                            </div>
                            <div className="sc_service_padding">
                                <h4 className="text-white">
                                    <a href="https://integro.ancorathemes.com/services/introduction/" className="text-white">Introduction</a>
                                </h4>
                                <p className="text-white para_w_100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div data-post-id="22541" className="">
                    <div className="d-flex justify-content-between gap_workflow">
                        <div className="">
                        <h6 className="text-white d-flex justify-content-center align-items-center p-3 mb-0 round_color" >
                            02
                            </h6>
                        <div className="sc_services_item_timeline"></div>
                            </div>
                            <div className="sc_service_padding">
                                <h4 className="text-white">
                                    <a href="https://integro.ancorathemes.com/services/introduction/" className="text-white">Strategy &amp; Intake</a>
                                </h4>
                                <p className="text-white para_w_100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris.
                                </p>
                            </div>
                        </div>
                       
                    </div>
                    <div data-post-id="22541" className="">
                    <div className="d-flex justify-content-between gap_workflow">
                        <div className="">
                        <h6 className="text-white d-flex justify-content-center align-items-center p-3 mb-0 round_color" >
                            03
                            </h6>
                        <div className="sc_services_item_timeline"></div>
                            </div>
                            <div className="sc_service_padding">
                                <h4 className="text-white">
                                    <a href="https://integro.ancorathemes.com/services/introduction/" className="text-white">Meeting</a>
                                </h4>
                                <p className="text-white para_w_100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris.
                                </p>
                            </div>
                        </div>
                       
                    </div>
                    <div data-post-id="22541" className="">
                    <div className="d-flex justify-content-between gap_workflow">
                        <div className="">
                        <h6 className="text-white d-flex justify-content-center align-items-center p-3 mb-0 round_color" >
                            04
                            </h6>
                        <div className="sc_services_item_timeline"></div>
                            </div>
                            <div className="sc_service_padding">
                                <h4 className="text-white">
                                    <a href="https://integro.ancorathemes.com/services/introduction/" className="text-white">Goals &amp; KPI</a>
                                </h4>
                                <p className="text-white para_w_100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris.
                                </p>
                            </div>
                        </div>
                       
                    </div>
                    <div data-post-id="22541" className="">
                    <div className="d-flex justify-content-between gap_workflow">
                        <div className="">
                        <h6 className="text-white d-flex justify-content-center align-items-center p-3 mb-0 round_color" >
                            05
                            </h6>
                        <div className="sc_services_item_timeline"></div>
                            </div>
                            <div className="sc_service_padding">
                                <h4 className="text-white">
                                    <a href="https://integro.ancorathemes.com/services/introduction/" className="text-white">Scope of Work</a>
                                </h4>
                                <p className="text-white para_w_100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris.
                                </p>
                            </div>
                        </div>
                       
                    </div>
                    <div data-post-id="22541" className="">
                    <div className="d-flex justify-content-between gap_workflow">
                        <div className="">
                        <h6 className="text-white d-flex justify-content-center align-items-center p-3 mb-0 round_color" >
                            06
                            </h6>
                        <div className="sc_services_item_timeline"></div>
                            </div>
                            <div className="sc_service_padding">
                                <h4 className="text-white">
                                    <a href="https://integro.ancorathemes.com/services/introduction/" className="text-white">Ad Types</a>
                                </h4>
                                <p className="text-white para_w_100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris.
                                </p>
                            </div>
                        </div>
                       
                    </div>
                    <div data-post-id="22541" className="">
                    <div className="d-flex justify-content-between gap_workflow">
                        <div className="sc_service_padding">
                        <h6 className="text-white d-flex justify-content-center align-items-center p-3 mb-0 round_color" >
                            07
                            </h6>
                            </div>
                            <div className="sc_service_padding">
                                <h4 className="text-white">
                                    <a href="https://integro.ancorathemes.com/services/introduction/" className="text-white">Budget Outline</a>
                                </h4>
                                <p className="text-white para_w_100">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris.
                                </p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

                <Footer />

                {/* Scroll To Top */}
                <div className="scroll-top">
                    <svg
                        className="progress-circle svg-content"
                        width="100%"
                        height="100%"
                        viewBox="-1 -1 102 102"
                    >
                        <path
                            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                            style={{
                                transition: "stroke-dashoffset 10ms linear 0s",
                                strokeDasharray: "307.919, 307.919",
                                strokeDashoffset: "307.919"
                            }}
                        />
                    </svg>
                </div>
            </ScrollToTop>
        </>

    )
}