import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link, useLocation, useParams } from "react-router-dom";
import ScrollToTop from "../component/ScrollToTop";



export default function BlogDetail() {
    const id = useLocation();
    console.log(id.state.id, "id")
    return (
        <>
          <ScrollToTop>
            <Header />
            <div className="breadcumb-wrapper style2 bg-smoke">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">

                            <div className="breadcumb-content justify-content-center">
                                <ul className="breadcumb-menu">
                                    <li>
                                        <a href="index.html">Home</a>
                                    </li>
                                    <li>
                                        <a href="blog.html">Blog</a>
                                    </li>
                                    <li>Everything You Should Know About Return</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* blog-details-area */}
            <section className="blog__details-area space">
                <div className="container">
                    <div className="blog__inner-wrap">
                        <div className="row justify-content-center">
                            <div className="col-70">
                                <div className="blog__details-wrap">
                                    <div className="blog__details-thumb">
                                        <img src="./images/blog/blog_details01.jpg" alt="img" />
                                    </div>
                                    <div className="blog__details-content">
                                        <div className="blog-post-meta">
                                            <ul className="list-wrap">
                                                <li>March 26, 2024</li>
                                                <li>
                                                    <a href="blog.html">Branding</a>
                                                </li>
                                                <li>
                                                    <a href="blog.html">by Ashton Porter</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <h2 className="title">
                                            Everything You Should Know About Return
                                        </h2>
                                        <p>
                                            BaseCreate is pleased to announce that it has been
                                            commissioned by Leighton Asia reposition its brand. We will
                                            help Leighton Asia evolve its brand strategy, and will be
                                            responsible updating Leighton Asia’s brand identity, website,
                                            and other collaterals.
                                        </p>
                                        <p>
                                            For almost 50 years Leighton Asia, one of the region’s largest
                                            and most respected construction companies, has been
                                            progressively building for a better future by leveraging
                                            international expertise with local intelligence. In that time
                                            Leighton has delivered some of Asia’s prestigious buildings
                                            and transformational infrastructure projects.
                                        </p>
                                        <blockquote>
                                            <img
                                                className="blockquote-icon"
                                                src="./images/icon/quote.svg"
                                                alt="img"
                                            />
                                            <p>
                                                “It’s a pleasure working with Bunker. They understood our
                                                brand positioning guidelines and translated them beautifully
                                                consistently into our on-going marketing comms”
                                            </p>
                                        </blockquote>
                                        <p>
                                            Leighton Asia’s brand refreshment will help position the
                                            company to meet the challenges of future, as it seeks to lead
                                            the industry in technological innovation and sustainable
                                            building practices to deliver long-lasting value for its
                                            clients.
                                        </p>
                                        <div className="blog__details-inner">
                                            <div className="row align-items-center">
                                                <div className="col-sm-6">
                                                    <div className="blog__details-inner-thumb">
                                                        <img
                                                            src="./images/blog/blog_details02.jpg"
                                                            alt="img"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="blog__details-inner-thumb">
                                                        <img
                                                            src="./images/blog/blog_details03.jpg"
                                                            alt="img"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            But in order that you may see whence all this born error of
                                            those who accuse pleasure and praise pain, I will open the
                                            whole matter, and explain the very things which were said by
                                            that discoverer of truth and, as it were, the architect of a
                                            happy life.
                                        </p>
                                        <p>
                                            Always ready to push the boundaries, especially when it comes
                                            to our own platform maximum analytical eye to create a site
                                            that was visually engaging and also optimised
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid p-0 overflow-hidden">
                <div className="slider__marquee clearfix marquee-wrap">
                    <div className="marquee_mode marquee__group">
                        <h6 className="item m-item">
                            <Link to="/service">
                                <i className="fas fa-star-of-life" />
                                Branding Design
                            </Link>
                        </h6>
                        <h6 className="item m-item">
                            <Link to="#">
                                <i className="fas fa-star-of-life" />
                                Web Development
                            </Link>
                        </h6>
                        <h6 className="item m-item">
                            <Link to="#">
                                <i className="fas fa-star-of-life" />
                                Metaverse Development
                            </Link>
                        </h6>
                        <h6 className="item m-item">
                            <Link to="#">
                                <i className="fas fa-star-of-life" />
                                Artificial intelligence
                            </Link>
                        </h6>
                    </div>
                </div>
            </div>
            <Footer />
          </ScrollToTop>

        </>
    )
}
