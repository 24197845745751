import { useState } from "react";

export default function ContactForm(){
    const [input, setInput] = useState({
        fname: '',
        website: '',
        email: '',
        mobile: '',
        message: '',
      });
      const [validations, setValidations] = useState({
        fname: false,
        website: false,
        email: false,
        mobile: false,
        message:false,
      });
      const [verror, setVerror] = useState('');
    
     const validationRules = {
        fname: 'First name is required',
        website: 'Website URL is required',
        email: 'Email is required',
        message:'Message is required',
        mobile: 'Mobile is required',
      };
    
    
    const handelInputs = (e) => {
        const { name, value } = e.target;
        setInput({ ...input, [name]: value });
        setValidations({ ...validations, [name]: false });
      };
     const handelSubmit = () => {
       
        for (const field in input) {
        
          if (!input[field]) {
            alert("run")
            setValidations({ ...validations, [field]: true });
            setVerror(validationRules[field]);
            return;
          }
          const MOBILE_REGX = /^[0-9]{10}$/;
          const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const WEBSITEURL_REGEX=/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    
          if (field === 'email' && !EMAIL_REGEX.test(input.email)) {
            setValidations({ ...validations, email: true });
            setVerror('Valid Email Required.');
            return;
          }
          if (field === 'mobile' && !MOBILE_REGX.test(input.mobile)) {
            setValidations({ ...validations, mobile: true });
            setVerror('Valid Mobile Required.');
            return;
          }
          if (field === 'website' && !WEBSITEURL_REGEX.test(input.website)) {
            setValidations({ ...validations, website: true });
            setVerror('Valid Url Required.');
            return;
          }
          
        }
        
        console.log(input)
      
      };
    return(
        <div className="contact-area-1 space bg-theme">
        <div
            className="contact-map shape-mockup wow img-custom-anim-left"
            data-wow-duration="1.5s"
            data-wow-delay="0.2s"
            data-left="10px"
            data-top="-40px"
            data-bottom="10px"
        >
            <iframe
                src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&t=m&z=10&output=embed&iwloc=near"
                allowFullScreen=""
                loading="lazy"
            />
            {/* <img src="./images/bg/breadcumb-bg1-6.jpg" alt="img" className="h-50 w-100" /> */}

        </div>
        <div className="container">
            <div className="row align-items-center justify-content-end">
                <div className="col-lg-6">
                    <div className="contact-form-wrap">
                        <div className="title-area mb-30">
                            <h2 className="sec-title">Have Any Project on Your Mind?</h2>
                            <p>
                                Great! We're excited to hear from you and let's start something
                            </p>
                        </div>
                        <form
                            action="mail.php"
                            method="POST"
                            className="contact-form ajax-contact"
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control style-border"
                                            name="fname"
                                            id="name"
                                            placeholder="Full name*"
                                            value={input.fname}
                                            onChange={handelInputs}
                                        />
                                    </div>
                                 {validations.fname &&
                                      verror && <>
                                      <div>
                                        <p className="mb-0 d-flex align-items-center error_message">
                                          <img src="../images/icons8-warning-20.png" alt="alert" className="small_icone me-1" />
                                          {verror}
                                        </p>
                                        </div>
                                      </>
                                    }
                                </div>                                    
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control style-border"
                                            name="website"
                                            id="website"
                                            placeholder="Organisation Name / Website URL *"
                                            value={input.website}
                                            onChange={handelInputs}
                                        />
                                    </div>
                                 {validations.website &&
                                      verror && <>
                                   <p className="mb-0 d-flex align-items-center error_message">
                                        <img src="../images/icons8-warning-20.png" alt="alert" className="small_icone me-1" />
                                          {verror}
                                        </p>
                                      </>
                                    }
                                
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control style-border"
                                            name="email"
                                            id="email"
                                            placeholder="Email address*"
                                            value={input.email}
                                            onChange={handelInputs}
                                        />
                                    </div>
                                    {validations.email &&
                                      verror && <>
                                        <p className="mb-0 d-flex align-items-center error_message">
                                           <img src="../images/icons8-warning-20.png" alt="alert" className="small_icone me-1" />
                                          {verror}
                                        </p>
                                      </>
                                    }
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control style-border"
                                            name="mobile"
                                            id="mobile"
                                            placeholder="Phone Number *"
                                            value={input.mobile}
                                            onChange={handelInputs}
                                        />
                                    </div>
                                    {validations.mobile &&
                                      verror && <>
                                        <p className="mb-0 d-flex align-items-center error_message">
                                            <img src="../images/icons8-warning-20.png" alt="alert" className="small_icone me-1" />
                                          {verror}
                                        </p>
                                      </>
                                    }
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            placeholder="How Can We Help You*"
                                            id="contactForm"
                                            className="form-control style-border"
                                            defaultValue={""}
                                            value={input.message}
                                            onChange={handelInputs}
                                        />
                                    </div>
                                    {validations.message &&
                                      verror && <>
                                        <p className="mb-0 d-flex align-items-center error_message">
                                            <img src="../images/icons8-warning-20.png" alt="alert" className="small_icone me-1" />
                                          {verror}
                                        </p>
                                      </>
                                    }
                                </div>
                            </div>
                            <div className="form-btn col-12">
                                <button type="submit" className="btn mt-20" onClick={handelSubmit}>
                                    <span className="link-effect">
                                        <span className="effect-1">SEND MESSAGE</span>
                                        <span className="effect-1">SEND MESSAGE</span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}