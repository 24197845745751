import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import ScrollToTop from "../component/ScrollToTop";
import ContactForm from "../component/ContactForm";

export default function Contact() {
    
    return (
        <>
            <ScrollToTop>
                <Header />
                <div
                    className="breadcumb-wrapper"
                    style={{ backgroundImage: `url('./images/bg/breadcumb-bg1-6.jpg')` }}
                >
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">Get In Touch</h1>
                        </div>
                    </div>
                </div>

                <div className="feature-area-1 space">
                    <div className="container">
                        <div className="row gy-4 align-items-center justify-content-center">
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/location-pin-alt.svg" alt="icon" />
                                    </div>
                                    <div className="feature-card-details">
                                        <h4 className="feature-card-title">
                                            <Link to="project.html">Headquarters</Link>
                                        </h4>
                                        <p className="feature-card-text mb-0">
                                        B-124 Royal Plaza, Varachha Main Rd, Near Bapa Sitaram Chowk, Nana Varachha, 
                                        </p>
                                        <p className="feature-card-text">Surat, Gujarat 395011, India  </p>
                                        <Link to="https://maps.google.com" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">Get direction</span>
                                                <span className="effect-1">Get direction</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/speech-bubble.svg" alt="icon" />
                                    </div>
                                    <div className="feature-card-details">
                                        <h4 className="feature-card-title">
                                            <Link to="project.html">Email Address</Link>
                                        </h4>
                                        <p className="feature-card-text mb-0">support@hopeflymeta.com</p>
                                        <p className="feature-card-text">support@hopeflymeta.com</p>
                                        <Link to="mailto:support@hopeflymeta.com" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">Send message</span>
                                                <span className="effect-1">Send message</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="feature-card">
                                    <div className="feature-card-icon">
                                        <img src="./images/icon/phone.svg" alt="icon" />
                                    </div>
                                    <div className="feature-card-details">
                                        <h4 className="feature-card-title">
                                            <Link to="project.html">Phone Number</Link>
                                        </h4>
                                        <p className="feature-card-text mb-0">+91 74878 53421 </p>
                                        <p className="feature-card-text">+91 74878 53421 </p>
                                        <Link to="tel:7487853421" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">Call anytime</span>
                                                <span className="effect-1">Call anytime</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               <ContactForm/>

                <div className="container-fluid p-0 overflow-hidden">
                    <div className="slider__marquee clearfix marquee-wrap">
                        <div className="marquee_mode marquee__group">
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Branding Design
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Web Development
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Metaverse Development
                                </Link>
                            </h6>
                            <h6 className="item m-item">
                                <Link to="#">
                                    <i className="fas fa-star-of-life" />
                                    Artificial intelligence
                                </Link>
                            </h6>
                        </div>
                    </div>
                </div>

                <Footer />

                {/* Scroll To Top */}
                <div className="scroll-top">
                    <svg
                        className="progress-circle svg-content"
                        width="100%"
                        height="100%"
                        viewBox="-1 -1 102 102"
                    >
                        <path
                            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                            style={{
                                transition: "stroke-dashoffset 10ms linear 0s",
                                strokeDasharray: "307.919, 307.919",
                                strokeDashoffset: "307.919"
                            }}
                        />
                    </svg>
                </div>
            </ScrollToTop>
        </>

    )
}