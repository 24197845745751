import Footer from "../component/Footer";
import Header from "../component/Header";

export default function CaseStudies(){
    return(
        
        <>
        <Header />
        <Footer/>
        </>
     )
}